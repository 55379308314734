import { ISO_DATE_FORMAT } from '@/common/utility'
import { CustomerInfo } from '@/models/customer-info'
import { DiscountProfile } from '@/models/discount-profile'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import moment from 'moment'
import { Direction } from '@/models/direction'
import { Assignation } from '@/models/enviaya-account/assignation'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { Fee } from '@/models/Fee'
import { Group } from '@/models/Group'
import { InvoiceDirection } from '@/models/invoice-direction'
import { VipSupport } from '@/models/vipSupport'
import { Audit } from './Audit'
import { isEmpty, IsIn, IsNotEmpty, isNumberString, Validate, ValidateIf, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { AbstractModel } from './abstract-model'
import i18n from '@/i18n'
import { CarrierAccount } from '@/models/carrier-account'

// @ts-ignore
import localeData from '@/locales/corporate_accounts/corporate_account.json'
import { QuotingFilter } from './quoting-filter'
import { EnviayaAccountTS, SaldoJob } from '@/models/EnviayaAccountTS'
import { WhiteLabel } from '@/models/white-label'

export const ACCOUNT_TYPE_ENVIAYA = 1
export const ACCOUNT_TYPE_RESELLER = 2

export enum invoiceScheduleOptions {
  INVOICE_SCHEDULE_TWICE_PER_MONTH = 1,
  INVOICE_SCHEDULE_ONCE_PER_MONTH = 2
}

export enum ImportTaxesPayments {
  DESTINATION = 1,
  CARRIER_ACCOUNT = 2
}

export enum InsuranceOptions {
  DONT_INSURE_SHIPMENTS ='dont_insure_shipments',
  INSURE_SHOPPING_CART_VALUE ='insure_shopping_cart_value',
  INSURE_A_FIXED_AMOUNT ='insure_a_fixed_amount'
}

export enum CorporateAccountStatuses {
  ACTIVE = 1,
  DISABLED = 2,
  DELETED = 3,
  DISABLED_SYSTEM = 5
}

@ValidatorConstraint()
export class IsNumberOrBlank implements ValidatorConstraintInterface {
  public validate (value: string | number, _args: ValidationArguments) {
    return isEmpty(value) || (typeof value === 'string' && isNumberString(value)) || (typeof value === 'number' && value > 0)
  }
}

export type OnboardingData = {
  enviayaAccountId: number | undefined | null,
  salesExecutiveId: number | undefined | null,
  discountProfileId: number | string | undefined | null,
  addFreeSaldo: boolean,
  saldoType: number,
  totalAmount: number | string | undefined | null,
  currency: string,
  notifyCustomerAboutGift: boolean
}

@Exclude()
export class CorporateAccountTs extends AbstractModel {
  static get modelName () {
    return 'CorporateAccount'
  }

  constructor () {
    super()

    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  public wasValidated: boolean = false

  @Expose({ name: 'name' })
  public name: string | undefined

  @IsNotEmpty({ message: () => i18n.t('form.invalid_msg.blank').toString() })
  @Expose({ name: 'company' })
  public company: string | undefined

  @Expose({ name: 'destination_company' })
  @Transform(value => { return (value === 'mandatory') ? true : false }, { toClassOnly: true })
  @Transform(value => { return value ? 'mandatory' : 'optional' }, { toPlainOnly: true })
  public destinationCompany: boolean | string = false

  @Expose({ name: 'white_label_id' })
  public whiteLabelId: number | undefined

  @Expose({ name: 'white_label' })
  @Type(() => WhiteLabel)
  public whiteLabel: WhiteLabel | undefined

  @Expose({ name: 'reseller_white_label' })
  public resellerWhiteLabel: { id: number | undefined, enable_reseller: boolean | undefined, skip_reseller_rating: boolean | undefined } = { id: undefined, enable_reseller: undefined, skip_reseller_rating: undefined }

  @Expose({ name: 'reseller_white_label' })
  public resellerWhiteLabelId?: string

  @Validate(IsNumberOrBlank, { message: () => {
    return i18n.t('form.invalid_msg.blank_credit_line').toString()
  }})
  @Expose({ name: 'credit_line' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  @Transform(value => { return !value ? null : value }, { toPlainOnly: true })
  public creditLine: string | null = null

  @Expose({ name: 'credit_saldo', toClassOnly: true })
  public creditSaldo: string | undefined

  @Expose({ name: 'national_saldo', toClassOnly: true })
  public nationalSaldo: string | undefined

  @Expose({ name: 'international_saldo', toClassOnly: true })
  public internationalSaldo: string | undefined

  @Expose({ name: 'currency' })
  public currency: string = ''

  @Expose({ name: 'has_transactions', toClassOnly: true })
  public hasTransactions: boolean = false

  @Expose({ name: 'insurance' })
  public insurance: string = ''

  @Expose({ name: 'verification_status' })
  public verificationStatus: number | null = null

  @Expose({ name: 'carrier_account_types' })
  @Transform(value => value ?? 1, { toClassOnly: true })
  public carrierAccountTypes: number = 1

  @Expose({ name: 'grace_period' })
  @Transform(value => { return !value ? '' : value }, { toClassOnly: true })
  public gracePeriod: string = ''

  @Expose({ name: 'payment_period' })
  @Transform(value => { return !value ? '' : value }, { toClassOnly: true })
  public paymentPeriod: string = ''

  @Expose({ name: 'discount_profile' })
  @Type(() => DiscountProfile)
  public discountProfile: DiscountProfile | undefined

  @Expose({ name: 'discount_profile_id' })
  public discountProfileId: number | undefined

  @Expose({ name: 'status' })
  @IsIn(Object.values(CorporateAccountStatuses), { message: () => {
    return i18n.t('form.invalid_msg.blank_status').toString()
  }})
  public status: number | undefined

  @Expose({ name: 'invoice_direction_id' })
  public invoiceDirectionId: string | undefined

  @Expose({ name: 'referrer_id' })
  public referrerId: number | undefined

  @Expose({ name: 'direction_id' })
  public directionId: number | undefined

  @Expose({ name: 'direction' })
  public direction: Direction | undefined

  @Expose({ name: 'customer_info' })
  @Type(() => CustomerInfo)
  public customerInfo: CustomerInfo | undefined

  @Expose({ name: 'vip_support' })
  @Type(() => VipSupport)
  public vipSupport: VipSupport | undefined

  @Expose({ name: 'account_type' })
  public accountType: number | undefined

  @Expose({ name: 'custom_discounts_enabled' })
  public customDiscountsEnabled: Boolean | undefined

  @Expose({ name: 'reverse_clearing_enabled' })
  public reverseClearingEnabled: Boolean | undefined

  @Expose({ name: 'custom_surcharge_discounts_enabled' })
  public customSurchargeDiscountsEnabled: Boolean | undefined

  @Expose({ name: 'include_vat' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public includeVat: Boolean | undefined

  @Expose({ name: 'exclude_from_marketing' })
  public excludeFromMarketing: Boolean | undefined

  @Expose({ name: 'invoice_direction' })
  @Type(() => InvoiceDirection)
  public invoiceDirection: InvoiceDirection | undefined

  @Expose({ name: 'administrators' })
  @Type(() => EnviayaUserTS)
  public administrators: EnviayaUserTS[] = []

  @Expose({ name: 'administrator_ids', toClassOnly: true })
  @Transform(value => value, { toClassOnly: true })
  public administratorIds: number[] = []

  @Expose({ name: 'groups' })
  @Type(() => Group)
  public groups: Group[] = []

  @Expose({ name: 'users' })
  @Type(() => EnviayaUserTS)
  public users: EnviayaUserTS[] = []

  @Expose({ name: 'import_taxes_payment' })
  public importTaxesPayment: number | undefined

  @Expose({ name: 'insurance_amount' })
  @ValidateIf(o => o.insurance === InsuranceOptions.INSURE_A_FIXED_AMOUNT)
  @IsNotEmpty({
    message: () => 'form.invalid_msg.blank_insurance_amount'
  })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  @Transform(value => { return !value ? null : value }, { toPlainOnly: true })
  public insuranceAmount: number | undefined

  @Expose({ name: 'insurance_currency' })
  public insuranceCurrency: string | undefined

  @Expose({ name: 'service_name_layout' })
  public serviceNameLayout: number | null = null

  @Expose({ name: 'next_reseller_invoicing_date' })
  @Transform(value => {
    if (moment(value, ISO_DATE_FORMAT).isValid()) {
      return moment(value, ISO_DATE_FORMAT).format('L')
    } else return null
  })
  public nextResellerInvoicingDate?: string

  @Expose({ name: 'next_transaction_fee_invoicing_date' })
  @Transform(value => {
    if (moment(value, ISO_DATE_FORMAT).isValid()) {
      return moment(value, ISO_DATE_FORMAT).format('L')
    } else return null
  })
  public nextTransactionFeeInvoicingDate?: string

  @Expose({ name: 'next_reseller_transaction_fee_invoicing_date' })
  @Transform(value => {
    if (moment(value, ISO_DATE_FORMAT).isValid()) {
      return moment(value, ISO_DATE_FORMAT).format('L')
    } else return null
  })
  public nextResellerTransactionFeeInvoicingDate?: string

  @Expose({ name: 'fee' })
  @Transform((value) => {
    if (value) return value

    return new Fee()
  })
  @Type(() => Fee)
  public fee: Fee = new Fee()

  @Expose({ name: 'invoicing_emails', toClassOnly: true })
  @Transform(value => value ?? [], { toClassOnly: true })
  public invoicingEmails: string[] = []

  @Expose({ name: 'confirmed_invoicing_emails', toClassOnly: true })
  @Transform(value => value ?? [], { toClassOnly: true })
  public confirmedInvoicingEmails: string[] = []

  @Expose({ name: 'sales_executive_assignations', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => Assignation)
  public salesExecutiveAssignations: Assignation[] = []

  @Expose({ name: 'quoting_filter', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => QuotingFilter)
  public quotingFilter: QuotingFilter | null = null

  @Expose({ name: 'enable_third_party_providers' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public enableThirdPartyProviders: boolean = false

  @Expose({ name: 'zoho_contact_id' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public zohoContactId: string = ''

  @Expose({ name: 'audits' })
  @Type(() => Audit)
  public audits: Audit[] = []

  @Expose({ name: 'invoicing_schedule' })
  @Transform(value => { return (value === null || value === 0) ? undefined : value }, { toClassOnly: true })
  @Transform(value => { return (value === undefined) ? null : value }, { toPlainOnly: true })
  public invoicingSchedule: number | undefined

  @Expose({ name: 'invoicing_period' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public invoicingPeriod: string = ''

  @Expose({ name: 'next_invoicing_date' })
  @Transform(value => {
    if (moment(value, ISO_DATE_FORMAT).isValid()) {
      return moment(value, ISO_DATE_FORMAT).format('L')
    } else return null
  })
  public nextInvoicingDate?: string

  @Expose({ name: 'any_negative_saldo', toClassOnly: true })
  public anyNegativeSaldo: number = 1

  @Expose({ name: 'carrier_accounts', toClassOnly: true })
  @Type(() => CarrierAccount)
  public carrierAccounts: CarrierAccount[] = []

  @Expose({ name: 'enviaya_accounts' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  @Type(() => EnviayaAccountTS)
  public enviayaAccounts: EnviayaAccountTS[] = []

  @Expose({ name: 'created_at' })
  @Transform(value => {
    if (moment(value, ISO_DATE_FORMAT).isValid()) {
      return moment(value, ISO_DATE_FORMAT).format('L')
    } else return null
  })
  public createdAt?: string

  @Expose({ name: 'recalculate_saldo_jobs', toClassOnly: true })
  @Type(() => SaldoJob)
  public recalculateSaldoJobs: SaldoJob[] = []

  public get select_box_text () {
    return `${this.company} (${this.id})`
  }

  @Expose({ name: 'force_2fa' })
  @Transform(value => { return (value === null) ? false : value })
  public force2fa: boolean = false

  @Expose({ name: 'password_security' })
  @Transform(value => value ?? 0)
  public passwordSecurity: number = 0

  public get account_type () {
    return this.accountType
  }

  public get white_label_id () {
    return this.whiteLabelId
  }
}
